<template>
	<!--
	Al parecer Vue redibuja los elementos HERMANOS de un elemento que contiene v-if cuando la condicion cambia.
	En este caso, el v-if estaba en el contenedor (.phi-post-editor-event) pero cuando se modificaba el condicional rules.calendar,
	el bloque .phi-post-editor-blocks se redibujaba y se perdia la funcionalidad de Sortable.

	Algo similar pasaba con Tiny-MCE.  El elemento se instanciaba varias veces cuando cambiaba la condicion de v-if de sus elementos hermanos,
	disparando varias veces el mount() y causando conflictos con la inicializacion de la libreria TinyMce.

	Como "regla" para prevenir comportamiento inesperado, todo elemento que contiene un v-if no debe tener hermanos
	-->
    <div class="phi-post-editor">

		<div class="phi-post-editor-event">
			<div v-if="showCalendar && rules && rules.calendar">
				<phi-drawer :open="isOpen.eventEditor">
					<div class="editor-wrapper" v-if="post.event">
						<phi-event-editor v-model="post.event"></phi-event-editor>
					</div>

					<footer>
						<div class="ok" @click="closeEventEditor(true)">
							<mu-icon value="check"></mu-icon>
							<span>{{ $t('OK') }}</span>
						</div>
						<div class="cancel" @click="closeEventEditor(false)">
							<mu-icon value="close"></mu-icon>
							<span>{{ $t('action.cancel') }}</span>
						</div>
					</footer>
				</phi-drawer>

				<phi-drawer :open="!isOpen.eventEditor">
					<div class="phi-media handle" @click="openEventEditor">
						<template v-if="post.event">
							<mu-icon value="event_available" class="phi-media-figure"></mu-icon>
							<div class="phi-media-body">{{ $date(post.event.startDate, allDay ? 'day' : 'date') }}</div>
							<mu-icon value="cancel" class="phi-media-right" @click.stop="deleteEvent()"></mu-icon>
						</template>
						<template v-if="!post.event">
							<mu-icon value="event" class="phi-media-figure"></mu-icon>
							<div class="phi-media-body">{{$t('action.addToCalendar')}}</div>
						</template>
					</div>
				</phi-drawer>
			</div>
		</div>

		<div class="phi-post-editor-header">
			<input v-show="showSubject" type="text" class="phi-post-editor-title" :placeholder="$t('noun.post.Title')" v-model="post.title" @input="debounceSave()" />
			
			<div v-if="isDevice">				
            	<tiny-mce2 v-if="allowHtml" v-model="post.description" @input="debounceSave()" class="phi-post-editor-description"></tiny-mce2>
			</div>
			<div v-else>
				<tiny-mce v-if="allowHtml" v-model="post.description" @input="debounceSave()" :configuration="editorConfig" class="phi-post-editor-description"></tiny-mce>
			</div>
		</div>

		<div class="phi-post-editor-body-footer">
			<slot name="after-body"></slot>
		</div>

		<div class="phi-post-editor-blocks">

			<div v-for="editable in editables" :data-id="editable.block.id" :key="editable.block.id">
				<div v-if="!editable.deleted" class="editable-block">

					<div class="phi-block-toolbar phi-media">
						<div class="phi-media-body sortable-handle">
							<mu-icon :value="editable.definition.icon" />
						</div>

						<div class="phi-media-right">
							<mu-icon-menu
								icon="more_vert"
								:anchorOrigin="{horizontal: 'right', vertical: 'top'}"
      							:targetOrigin="{horizontal: 'right', vertical: 'top'}"
							>
								<mu-menu-item :title="$t('noun.post.preview')" @click="editable.setAction()"></mu-menu-item>
								<mu-menu-item v-for="(action, actionName) in editable.definition.actions" :title="$t('noun.post.'+ action.title)" @click="editable.setAction(actionName)" :key="actionName"></mu-menu-item>
							</mu-icon-menu>
						</div>
					</div>

					<phi-block
						:post="post"
						:block="editable.block"
						:action="editable.currentAction"
						@change="editable.save()"
						@destroy="editable.destroy()"
						@reset="editable.setAction()"
					></phi-block>
				</div>
			</div>

		</div>

		<div class="phi-post-editor-adder">
			<div v-if="availableBlocks.length">
				<phi-drawer :open="isOpen.attachments">
					<div v-for="blockDefinition in availableBlocks" class="phi-media" :class="'type-'+blockDefinition.type" @click="createBlock(blockDefinition.type)" :key="blockDefinition.type">
						<mu-icon class="phi-media-figure" :value="blockDefinition.icon"></mu-icon>
						<p class="phi-media-body" v-text="$t('noun.'+ blockDefinition.title)"></p>
					</div>
					<div class="toggler phi-media" @click="isOpen.attachments = false">
						<mu-icon value="close" class="phi-media-figure"></mu-icon>
						<p class="phi-media-body">{{ $t('action.cancel') }}</p>
					</div>
				</phi-drawer>

				<phi-drawer :open="!isOpen.attachments">
					<div class="actions">
						<div class="toggler phi-media attach" @click="isOpen.attachments = true">
							<mu-icon value="attachment" class="phi-media-figure"></mu-icon>
							<p class="phi-media-body">{{ $t('action.post.addAttachment') }}</p>
						</div>
						<slot name="action"></slot>
					</div>
				</phi-drawer>
			</div>
		</div>

		<div class="phi-post-editor-quotes" v-if="post.quotes && post.quotes.length">
            <phi-post
                v-for="(quote, key) in post.quotes"
				:key="quote.id"
                :dateFormat="dateFormat"
                v-model="post.quotes[key]"
            >
            </phi-post>
		</div>

		<div>
			<phi-post-editor-settings
				v-if="rules && showSettings"
				:rules="rules"
				:post="post"
				@input="save()"
			></phi-post-editor-settings>
		</div>

    </div>
</template>

<script>
import Sortable from 'sortablejs';

import PhiBlock from '../Block.vue';
import PhiEventEditor from '../Event/Editor.vue';
import PhiPostEditorSettings from './Editor/Settings.vue';

const TinyMce = () => import('../../TinyMce.vue');
const TinyMce2 = () => import('../../TinyMce2.vue');

export default {
	name: "phi-post-editor",

	components: {
		PhiBlock,
		PhiEventEditor,
		PhiPostEditorSettings,
		TinyMce,
		TinyMce2,
	},

	props: {
		post: {
			type: Object,
			required: true
		},

        dateFormat: {
			type: String,
			default: 'h:mm a'
		},

		allowHtml: {
			type: Boolean,
			default: true
		},		

		showSettings: {
			type: Boolean,
			default: true
		},

		showCalendar: {
			type: Boolean,
			default: true
		},

		showSubject: {
			type: Boolean,
			default: true
		},

        editorConfig: {
			type: Object,
			default() {
                return {};
            }
		},

        autoSave: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			rules: null,
			editables: [],
			isOpen: {
				attachments: false,
				eventEditor: false
			},
			saveTimeout: null,
			initialEvent: null,
			isDevice: true
		}
	},

	computed: {
		api() {
			return this.$store.state.api;
		},

		availableBlocks() {
			if (!this.rules || !this.rules.attach) {
				return [];
			}

			var retval = [];
			this.rules.attach.forEach(blockType => {
				let definition = PhiBlock.getDefinition(blockType);
				if (definition) {
					/* Google Document block only available in web version - not mobile */
					if (typeof isMobile == "undefined" || (isMobile && blockType != 'googleDocument')) {
						retval.push(Object.assign({type: blockType}, definition));
					}
				}
			});
			return retval;
		},

		allDay() {
			if (!this.post.event) {
				return false;
			}
			return (this.post.event.allDay === true || this.post.event.allDay === 1 || this.post.event.allDay === "1" || this.post.event.allDay === "true");
		}
	},

	methods: {
		toEditable(block, initialAction) {
			var vm = this;
			return {
				block,
				definition: PhiBlock.getDefinition(block.type),
				currentAction: initialAction,
				deleted: false,
				menuIsOpen: false,

				setAction(actionName) {
					this.currentAction = actionName == 'default' ? null : actionName;
				},

				save() {
					var blocksUrl = "/posts/" + vm.post.id + "/blocks";
					if (this.block.id) {
						vm.api.put(blocksUrl + "/" + this.block.id, this.block);
					} else {
						vm.api.post(blocksUrl, this.block).then(createdBlock => this.block.id = createdBlock.id);
					}
					vm.change();
				},

				destroy() {
					this.deleted = true;
					vm.post.blocks.splice(vm.post.blocks.indexOf(this.block), 1);
					if (this.block.id) {
						vm.api.delete("/posts/" + vm.post.id + "/blocks/" + this.block.id);
						vm.change();
					}
				}
			};
		},

		createBlock(type) {
			var newBlock = {
				type,
				url:   null,
				order: this.post.blocks.length + 1
			};

			this.post.blocks.push(newBlock);
			this.editables.push(this.toEditable(newBlock, "edit"));
			this.isOpen.attachments = false;
		},

		change() {
			this.$emit("change");
		},

		debounceSave() {
			clearTimeout(this.saveTimeout);
			this.saveTimeout = setTimeout(this.save, 500);
		},

		save() {
			if (!this.autoSave) {
				return;
			}

			this.isOpen.eventEditor = false;
			return this.$store.state.api
				.put(`/posts/${this.post.id}`, this.post)
				.then(response => {
					if (response.event && response.event.id) {
						this.post.event.id = response.event.id;
					}
				});
		},

		openEventEditor() {
			if (!this.post.event) {
				let startDate = new Date();
				let endDate   = new Date();

				startDate.setHours(8, 0, 0);
				endDate.setHours(10, 0, 0);

				this.$set(this.post, 'event', {
					startDate: startDate.getTime() / 1000,
					endDate:   endDate.getTime() / 1000,
					allDay:    false,
					repeat:    null
				});
			} else {
				this.initialEvent = Object.assign({}, this.post.event);
			}

			this.isOpen.eventEditor = true;
		},

		closeEventEditor(isOk) {
			isOk ? this.save() : this.post.event = this.initialEvent;
			this.isOpen.eventEditor = false;
		},

		deleteEvent() {
			if (!confirm(this.$t('question.deleteThisEvent'))) {
				return;
			}

			this.post.event = null;
			return this.save();
		},

        loadRules() {
            return this.$store.state.api
                .get(`/people/${this.$store.state.user.id}/posts/types/${this.post.type.singular}/settings`)
                .then(incoming => {
					this.rules = incoming;
                });
        }
	},

	mounted() {
		
		if (typeof device == "undefined" || typeof device.platform == "undefined") {
            this.isDevice = false;
        }

		this.loadRules();

		var vm         = this;
		this.editables = this.post.blocks.map(block => this.toEditable(block));

		Sortable.create(this.$el.querySelector('.phi-post-editor-blocks'), {
			handle: '.sortable-handle',
			animation: 150,
			forceFallback: true, // it will not work with youtube blocks otherwise.  This took me HOURS to discover :(
			onUpdate() {
				vm.api.put("/posts/" + vm.post.id + "/blocks", this.toArray());
				vm.change();
			}
		});
	}
}
</script>


<style lang="scss">
.phi-post-editor {

	.phi-post-editor-title {
		display: block;
		box-sizing: border-box;
		width: 100%;

		border: none;
		padding: 6px;
		font-size: 1.2em;
		font-weight: 300;

		border-bottom: 2px solid #f2f2f2;
	}

	.phi-post-editor-description {
		width: 100%;
		height: auto;
		min-height: 48px;
		resize: none;
		border: none;
		padding: 6px;
		font-size: 1em;
		font-weight: 300;
	}

	.sortable-ghost {
		opacity: 0;
	}

	.phi-post-editor-adder {
		.phi-media {
			align-items: center;
			cursor: pointer;
			padding: 8px 12px;

			&:hover {
				background-color: rgba(0, 0, 0, 0.04);
			}
		}

		.phi-media-figure {
			font-size: 1.4em;
			line-height: 1.7em;
			text-align: center;
		}
	}

	.type-list {
		.type-youtube .phi-media-figure {
			color: #e62117;
		}
	}

	.editable-block {
		margin-bottom: 24px;
		padding: 6px;

		.phi-block-toolbar {
			padding: 0;

			margin-bottom: 6px;
			background-color: rgba(0, 0, 0, .08);
			border-radius: 4px;

			.sortable-handle {
				display: flex;
				align-items: center;
				.mu-icon {
					padding: 6px;
					color: rgba(0, 0, 0, 0.4);
				}
			}

			.phi-media-body {
				cursor: move;
			}

			.phi-media-right {
				.mu-icon-button {
					width: auto;
					height: auto;
					padding: 6px 12px;

					.mu-icon {
						padding-top: 2px;
					}
				}
			}


		}
	}

	.phi-post-editor-event {
		.editor-wrapper {
			padding: 12px;
		}

		.handle {
			cursor: pointer;

			&:hover {
				background-color: rgba(0, 0, 0, 0.04);
			}

			.phi-media-right {
				color: #900;
				opacity: 0.8;
			}
		}

		footer {
			display: flex;

			& > div {
				flex: 1;
				padding: 18px;
				cursor: pointer;

				display: flex;
				align-items: center;

				.mu-icon {
					margin-right: 0.5em;
				}

				&:hover {
					background-color: rgba(0, 0, 0, 0.04);
				}
			}
		}
	}

	.phi-post-editor-quotes {
		border-left: 3px solid dodgerblue;
		padding-left: 4px;
		margin: 12px 0 0 4px;

		transform-origin: 0 0 0;
		transform: scale(.9);
	}

	.actions {
		display: flex;

		.attach {
			flex: 1;
		}
	}


	.phi-post-editor-cron {
		label,
		.publish-date {
			display: inline-block;
			margin-right: 1em;
		}
	}
}
</style>